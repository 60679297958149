<ng-container *transloco="let t; read: 'menu'">
  <lc-header-menu [model]="items" data-cy="main-menu">
    <ng-template pTemplate="start">
      <img
        alt="{{ t('logoImageAlt') }}"
        [src]="(logoImage$ | async).thumbnailM"
        height="40"
        class="mr-2 flex cursor-pointer"
        *ngIf="logoImage$ | async"
        routerLink="/"
      />
      <strong
        *ngIf="
          (logoImage$ | async) === null || (logoImage$ | async) === undefined
        "
        routerLink="/"
        class="cursor-pointer white-space-nowrap"
        >{{ instanceName$ | async }}</strong
      >
    </ng-template>
    <ng-template pTemplate="end">
      <div class="flex justify-content-center gap-2">
        <div class="search-fake-input" (click)="openSearch()" tabindex="0">
          <i class="pi pi-search mr-2"></i>
          <span class="search-label">{{ t("searchPlaceholder") }}</span>
        </div>
        <p-menu
          #menu
          [model]="userMenuItems"
          [popup]="true"
          data-cy="auth-menu"
          styleClass="wide-menu"
        ></p-menu>
        <p-avatar
          [image]="
            (currentUser$ | async).avatar
              ? (currentUser$ | async).avatar.thumbnailS
              : 'assets/user.png'
          "
          (click)="menu.toggle($event)"
          *isLoggedIn="true"
          data-cy="auth-menu-button"
          styleClass="mr-2"
          size="normal"
          shape="circle"
        ></p-avatar>
        <p-button
          icon="pi pi-user"
          routerLink="login"
          severity="secondary"
          *isLoggedOut="true"
          [text]="true"
          data-cy="navbar-login"
        ></p-button>
      </div>
    </ng-template>
  </lc-header-menu>
</ng-container>
